import { m } from 'framer-motion';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import BlogCard from '../BlogCardDeck/BlogCard';
import SubscribeForm from '../Forms/SubscribeForm';

const BlogPosts = ({
  blogs,
  hideSubscribeForm = false,
  isMobile = false,
  isTablet = false,
}) => {
  const colSize = isMobile ? 12 : isTablet ? 6 : 4;
  return (
    <>
      {(!blogs || blogs.length === 0) && (
        <h2 className="text-center" style={{ padding: '96px' }}>
          No posts to show
        </h2>
      )}
      <Row className="justify-content-start">
        {blogs.slice(0, 12).map(post => (
          <Col md={colSize} key={post.title} className="blog-card">
            <m.div
              key={post.slug}
              initial={{ opacity: 0, scale: 0, filter: 'blur(5px)' }}
              animate={{ opacity: 1, scale: 1, filter: 'blur(0)' }}
              exit={{ filter: 'blur(5px)', opacity: 0, scale: 0 }}
              transition={{ type: 'tween', delay: 0.1, duration: 0.5 }}
            >
              <BlogCard card={post} />
            </m.div>
          </Col>
        ))}
      </Row>
      {!hideSubscribeForm && <SubscribeForm />}
      {blogs.length > 6 && (
        <Row className="justify-content-start">
          {blogs.slice(12, 15).map(post => (
            <Col md={colSize} key={post.title} className="blog-card">
              <m.div
                key={post.slug}
                initial={{ opacity: 0, scale: 0, filter: 'blur(5px)' }}
                animate={{ opacity: 1, scale: 1, filter: 'blur(0)' }}
                exit={{ filter: 'blur(5px)', opacity: 0, scale: 0 }}
                transition={{ type: 'tween', delay: 0.1, duration: 0.5 }}
              >
                <BlogCard card={post} />
              </m.div>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

export default BlogPosts;
